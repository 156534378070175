@import "./_sass-essentials/essentials.scss";

.pt--tw-text {
  &.width-full-screen {
    .paragraph-inner {
      @include default-boxed-content-width;
    }
  }

  &.pg--vm--featured {
    background-color: $c-brand-primary;
    color: $c-white;
    padding-top: $co-mob;
    padding-bottom: $co-mob;

    &.paragraph > .paragraph-inner {
      padding-left: $l-gutter-half;
      padding-right: $l-gutter-half;
    }

    @include mq($bp-desktop-min) {
      padding-top: $co-desk;
      padding-bottom: $co-desk;
    }

    @include mq($bp-md-min) {
      padding-top: $co-lg;
      padding-bottom: $co-lg;
    }
  }
}
