.pt--tw-text.width-full-screen .paragraph-inner {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .pt--tw-text.width-full-screen .paragraph-inner {
    max-width: 750px;
  }
}

@media screen and (min-width: 992px) {
  .pt--tw-text.width-full-screen .paragraph-inner {
    max-width: 990px;
  }
}

@media screen and (min-width: 1200px) {
  .pt--tw-text.width-full-screen .paragraph-inner {
    max-width: 1200px;
  }
}

.pt--tw-text.pg--vm--featured {
  background-color: #FFD400;
  color: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
}

.pt--tw-text.pg--vm--featured.paragraph > .paragraph-inner {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 768px) {
  .pt--tw-text.pg--vm--featured {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 992px) {
  .pt--tw-text.pg--vm--featured {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
